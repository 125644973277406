import $ from 'jquery';
//import 'jquery-ui';
import React,{ useState } from 'react';
import AOS from 'aos';

//const appUrl="http://localhost:8086/dhanvantari_admin/";
//const appUrtPath="http://localhost:8086/dhanvantari_admin/upd/";
//const appUrl="https://cms.mbdhwellness.com/";
//const appUrtPath="https://cms.mbdhwellness.com/mbdh/";
const appUrl="https://cms.dsbgroup.in/controller/pgControl.php?pm=";
const appUrtPath="https://cms.dsbgroup.in/dsbgroup_upd/";


export const appGetUrlPath=()=>{
  return appUrtPath;
}
export const appGetMainUrl=()=>{
  return appUrl;
}

export const pgIndexLimit=()=>{
  let pgIndex=[];
  for(let i=0;i<50;i++){
    pgIndex.push(i);
}
  return pgIndex;
}

export const writeJSLib=()=>{
  
  //alert("i min use effect");
  const scriptJqueryForm = document.createElement("script");
  scriptJqueryForm.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery.form/4.3.0/jquery.form.min.js"; // Replace with your script's path
  scriptJqueryForm.async = false;
  // Append the script to the document after the #app element is rendered
  document.getElementById("root").appendChild(scriptJqueryForm);

  // ----
  const scriptVendorMin = document.createElement("script");
  scriptVendorMin.src = process.env.PUBLIC_URL+"/vendor/vendor.min.js?v1=xdrtcjj"; // Replace with your script's path
  scriptVendorMin.async = false;
  // Append the script to the document after the #app element is rendered
  document.getElementById("root").appendChild(scriptVendorMin);

  //-----
  const scriptPlugMins = document.createElement("script");
  scriptPlugMins.src = process.env.PUBLIC_URL+'/plugins/plugins.min.js?v1=xswdfty78'; // Replace with your script's path
  scriptPlugMins.async = false;
  // Append the script to the document after the #app element is rendered
  document.getElementById("root").appendChild(scriptPlugMins);

   //-----
   const scriptBootStrapMin = document.createElement("script");
   scriptBootStrapMin.src = "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"; // Replace with your script's path
   scriptBootStrapMin.async = false;
   // Append the script to the document after the #app element is rendered
   document.getElementById("root").appendChild(scriptBootStrapMin);


   //-----
   const scriptBootStrpBundle = document.createElement("script");
   scriptBootStrpBundle.src = "https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js"; // Replace with your script's path
   scriptBootStrpBundle.async = false;
   // Append the script to the document after the #app element is rendered
   document.getElementById("root").appendChild(scriptBootStrpBundle);

   //-----
   const scriptPopUpper = document.createElement("script");
   scriptPopUpper.src = process.env.PUBLIC_URL+'/popupper.js?v1=xswdfty78'; // Replace with your script's path
   scriptPopUpper.async = false;
   // Append the script to the document after the #app element is rendered
   document.getElementById("root").appendChild(scriptPopUpper);


   const script = document.createElement("script");
        script.src = process.env.PUBLIC_URL+"/main.js";
        script.async = false;
        
        //script.onload = () => clsObject.scriptLoaded();
      
       document.body.appendChild(script);
          //this.forceUpdateHandler();
        //if(ifImgGallery)
            {
            $('.gallery-item').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        }
        AOS.init({
          once: true
        });   
}

export const CallCMSMapMethod_CBack=(obCls,frmValues,cBack=null)=>{
   
    var curl_type=frmValues['curl_type'];
 
     //alert("check url == "+curl_type);
     var divRes="";
     var getCallFunc=""
     if(frmValues['res_name'])
     {
       divRes=frmValues['res_name'];
     }
     if(frmValues['res_func'])
     {
       getCallFunc=frmValues['res_func'];
     }
         var ss=frmValues;
         //var xs = "{objs:" + JSON.stringify(ss) + "}";
        //alert(xs);
          $.ajax({
             crossDomain:true,
                 type: "POST",
                 url: appUrl+curl_type,
                 data:ss,
              success: function (data) {
                //alert(data);   
               //ajaxindicatorstop();
                  // return data;
                  if(divRes!="")
                  {
                      if ($("#" + divRes).length!=0)
                   $("#"+divRes).html(data);
                  }
                  if(cBack!==null)
                  {
                   var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                   var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                   //  eval(getCallFunc);
                  // alert(checkMsg);
                  return cBack(checkData,checkMsg);
                     }
                    
                 }
             });
 }
export const getRoleDetails=(roles,uriPath)=>{
  const listFltr = d => d.col2 === uriPath && d.status==='side';
  var topMenusList = roles.filter(listFltr);
  var listFilter;
  for(var i=0;i<topMenusList.length;i++){
    const childFltr = d => d.parent_id === topMenusList[i]["parent_id"] && d.status === 'side';
    listFilter = roles.filter(childFltr);
  }
  return listFilter;
}
export const getAccessRolesNew=(roles)=>{

    var getMenu="";
    const listFltr = d => (d.col6 === 'menu' || d.col6 === 'top_menu') && d.parent_id == 0;
    var topMenusList = roles.filter(listFltr);
    //alert(topMenusList.length);
    for (var i = 0; i < topMenusList.length; i++) {
        const childFltr = d =>d.col6 === 'menu' && d.parent_id === topMenusList[i]["id"] && d.col6 !== 'desk'
        var childMenuList = roles.filter(childFltr);
        var getChMenu = "";
        //alert(childMenuList.length + " = " + topMenusList[i]["id"]);
        if (childMenuList.length === 0) {
          //alert(topMenusList[i]["col1"]);
          if(topMenusList[i]["col1"]==="E-Consultation"){
            getMenu += " <li>" +
            "<a target=\"blank\" href = \"" + topMenusList[i]["col2"] + "\">" + topMenusList[i]["col1"] + "</a>" +
            "</li>";
          }else{
            getMenu += " <li>" +
                "<a href = \"/" + topMenusList[i]["col2"] + "\">" + topMenusList[i]["col1"] + "</a>" +
                "</li>";
          }
            
        } else {
            getMenu += "<li class=\"dropdown\">" +
            "<a href=\"#\">"+topMenusList[i]["col1"]+" <i class=\"ion-ios-arrow-down\"></i></a>";
               
            getChMenu = "<ul class=\"sub-menu\">";
            for (var ch = 0; ch < childMenuList.length; ch++) {
                const child2Fltr=d => d.parent_id === childMenuList[ch]["id"] && d.col6 === 'menu';
                var child2MenuList=roles.filter(child2Fltr);
                
                if(child2MenuList.length!=0){
                  getChMenu += "<li class=\"dropdown position-static\"><a href=\"" + childMenuList[ch]["col2"] + "\">" + childMenuList[ch]["col1"] + "<i class=\"ion-ios-arrow-right\"></i></a>";
                  getChMenu+="<ul class=\"sub-menu sub-menu-2\">";
                }else{
                  getChMenu += "<li><a href=\"/" + childMenuList[ch]["col2"] + "\">" + childMenuList[ch]["col1"] + "</a></li>";
                }
                for(var k=0;k<child2MenuList.length;k++){
                  getChMenu += "<li><a href=\"/" + child2MenuList[k]["col2"] + "\">" + child2MenuList[k]["col1"] + "</a></li>";
                }
                if(child2MenuList.length!=0){
                  getChMenu+="</ul></li>";
                }
            }
            getChMenu += "</ul>";
            getMenu += getChMenu + "</li>";
        }
  
        // getMenu += topMenusList[i]["col1"];
    }
    return getMenu;
   
  }
  export default CallCMSMapMethod_CBack