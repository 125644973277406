import React,{useEffect,useState} from 'react';
import './home_banner.css';
import {sortList} from '../function_lib/common_lib';
import TextToHtml from './text_html';
import { appGetUrlPath } from '../function_lib/common_cms';

export const Home_Banner2Div_n=(props)=>{
    useEffect(()=>{

    },[props.lsData]);
    
    var appPath=appGetUrlPath();

    return(<div data-aos="zoom-in-down" class="hero_home section">
    <div class="hero-slider swiper-container slider-nav-style-1 slider-dot-style-1 dot-color-white">
         <div class="swiper-wrapper">
            {/* content part */}
            {props.lsData.map((item,index)=>{
                //alert(JSON.stringify(item));
                 let styleRBg=""; 
                 if(item["col15"].trim()!==""){
                     styleRBg=item["col15"].trim();
                     //alert("i m in sytle=="+styleRBg);
                  }
                  let rfUrl="javascript:void(0)";
                  if(item["col7"].trim()!==""){
                     rfUrl=item["col7"].trim();
                  }
                  let rfText="know more";
                  if(item["col8"].trim()!==""){
                    rfText=item["col8"].trim();
                  }
                return(<div  class="hero-slide-item  bg-banner1 slider-height swiper-slide d-flex">
      <div  class="container align-self-center">
      <div class="row ">
          <div class="col-xl-6 col-lg-7 col-md-7 col-sm-7 bg-gray-ligh align-self-center text-center">
              <div class="hero-slide-content slider-animated-1  center">
                
                  <h2 class="title-1 white11">{item["col1"]}</h2>
                  <div><TextToHtml text={item["col2"]}/></div>
                  <a href={rfUrl} class="btn btn-lg btn-primary btn-hover-dark mt-5">{rfText}</a>
              </div>
          </div>
          <div class="col-xl-6 col-lg-5 col-md-5 col-sm-5 bg-gray-ligh  bg-rectangle-animation">
              <div class="hero-slide-image img-bg-shape">
                  <img src={appPath+item["col4"]} alt="banner" />
              </div>
          </div>
      </div>
  </div>
      
   </div>)
            })}
            {/* end of content part */}
         </div><div class="swiper-pagination swiper-pagination-white"></div>
            <div class="swiper-buttons">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
    </div>
</div>
</div>
         )
}

export const Home_Banner_WithNews_n=(props)=>{
    
    let appPath=appGetUrlPath();
    const [lData,setLData]=useState([]);
    let lsData=[];
    useEffect(()=>{
        //alert(JSON.stringify(props.lsData));
        if(props.lsData.length>0){
            //alert(props.lsData[0]["col14"]);
            if(props.lsData[0]["col14"]!==""){
                //alert(lsData[0]["col14"]);
                lsData=sortList(props.lsData,"col14",false,true);
                
                //alert("i min sorting");
            }else{
                lsData=props.lsData;
            }
            setLData(lsData);
            //alert(lData.length);
        }

    },[props.lsData]);
    return(<div data-aos="zoom-in-down" class="hero-slider-70 hero_home section">
        <div class="hero-slider swiper-container slider-nav-style-1 slider-dot-style-1 dot-color-white">
             <div class="swiper-wrapper">
{/* content part */}
{lData.map((item,index)=>{
     let styleRBg=""; 
     if(item["col15"].trim()!==""){
         styleRBg=item["col15"].trim();
         //alert("i m in sytle=="+styleRBg);
      }
      let rfUrl="javascript:void(0)";
      if(item["col7"].trim()!==""){
         rfUrl=item["col7"].trim();
      }
    return(<div class="hero-slide-item slider-height-2 swiper-slide d-flex">
       
       <div class="hero-bg-image">
       <a href={rfUrl}>
           <img src={appPath+item["col4"]} style={{"position":"relative"}} alt="banner"/>
        </a>
       </div>
       {item["col1"]!==""?
       <div class="container align-self-center overlay">
                        <div class="row justify-content-center">
                            <div class="col-md-8 offset-2 align-self-center m-auto">
                                <div class="hero-slide-content hero-slide-content-2 slider-animated-1 text-center">
                                    <span class="category">{item["col1"]}</span>
                                    <h2 class="title-1"><TextToHtml text={item["col2"]}/></h2>
                                    <p class="w-100"><TextToHtml text={item["col3"]}/></p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>:''}
    </div>)
})}
{/* end of content part */}

             </div>
    
    <div class="swiper-pagination swiper-pagination-white\"></div>
            <div class="swiper-buttons">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
    </div>
   
</div>
{/* <div id="latest_update">place test</div> */}
</div>
                )
}

export function Home_Banner_n(props){
    let appPath=appGetUrlPath();
    const [lData,setLData]=useState([]);
    let lsData=[];
    useEffect(()=>{
        //alert(JSON.stringify(props.lsData));
        if(props.lsData.length>0){
            //alert(props.lsData[0]["col14"]);
            if(props.lsData[0]["col14"]!==""){
                //alert(lsData[0]["col14"]);
                lsData=sortList(props.lsData,"col14",false,true);
                
                //alert("i min sorting");
            }else{
                lsData=props.lsData;
            }
            setLData(lsData);
            //alert(lData.length);
        }

    },[props.lsData]);

    return(<div data-aos="zoom-in-down" class="hero_home section">
             <div class="hero-slider swiper-container slider-nav-style-1 slider-dot-style-1 dot-color-white">
               <div class="swiper-wrapper">

                {props.lsData.map((item,index)=>{
                     let rfUrl="javascript:void(0)";
                     if(item["col7"].trim()!==""){
                        rfUrl=item["col7"].trim();
                     }
                    return (<div class="hero-slide-item slider-height-2 swiper-slide d-flex">
       <div class="hero-bg-image">
         <a href={rfUrl}>
           <img src={appPath+item["col4"]} alt="banner"/>
        </a>
       </div>
   </div>)
                })}

               </div><div class="swiper-pagination swiper-pagination-white"></div>
            <div class="swiper-buttons">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
    </div>
</div>
</div>
                )

}


export function Home_Banner(lsData){
    var appPath=appGetUrlPath();
    var strResp="";
  
    var strHeader="<div data-aos=\"zoom-in-down\" class=\"hero_home section\">"+
    "<div class=\"hero-slider swiper-container slider-nav-style-1 slider-dot-style-1 dot-color-white\">"+
         "<div class=\"swiper-wrapper\">";
    var strFooter="</div><div class=\"swiper-pagination swiper-pagination-white\"></div>"+
            "<div class=\"swiper-buttons\">"+
        "<div class=\"swiper-button-next\"></div>"+
        "<div class=\"swiper-button-prev\"></div>"+
    "</div>"+
"</div>"+
"</div>";
    for(var i=0;i<lsData.length;i++){
        let styleRBg=""; 
        if(lsData[i]["col15"].trim()!==""){
            styleRBg=lsData[i]["col15"].trim();
            //alert("i m in sytle=="+styleRBg);
         }
         let rfUrl="javascript:void(0)";
         if(lsData[i]["col7"].trim()!==""){
            rfUrl=lsData[i]["col7"].trim();
         }
       strResp+="<div class=\"hero-slide-item slider-height-2 swiper-slide d-flex\">"+
       
       "<div class=\"hero-bg-image\">"+
       "<a href=\""+rfUrl+"\">"+
           "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"banner\"/>"+
        "</a>"+
       "</div>"+
   "</div>";
    }
    return strHeader+strResp+strFooter;
}
export default Home_Banner;