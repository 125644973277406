import $ from 'jquery';
import {useEffect,useRef} from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Index from './pages/index';

//import  Test_Index from './pages/test_index';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
let jQuery=$;
function App() {
  const hasRun = useRef(false);
useEffect(()=>{
 
  if (hasRun.current) return; // Prevent running twice
  hasRun.current = true;



},[])


  return (
    <Router><Routes >
    {/* <Route exact path="/contactus" component={ContactUs}/> */}
    {/* <Route path="test" element={<Test_Index/>}/> */}
    <Route  path="/*" element={<Index/>}/>
  
</Routes ></Router>
  );
}

export default App;
